<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-container v-if="!loading">
    <div v-if="comeFromList" class="primary--text my-2">
      <v-btn x-small color="primary" text @click="backToList">
        <font-awesome-icon :icon="['fal','chevron-left']" class="mr-2" size="sm" />
        Revenir à la liste
      </v-btn>
    </div>
    <v-row>
      <v-col v-if="formResponse && fields.length" cols="12" md="3">
        <admission-status class="admission-status px-0" :survey-fields="fields" :survey-model="model.form"
                          :patient-model="model.patient" :admission-status="stay.status"
        />
      </v-col>
      <v-col cols="12" :md="formResponse ? '9' : '12'">
        <template v-if="stay.formResponses === null">
          <v-card-title class="px-0 font-alt">Nouvelle demande d'admission</v-card-title>
          <v-card-subtitle class="px-0 font-weight-light text-caption font-alt">
            Veuillez compléter le formulaire d'entrée pour valider votre dossier d'admission
          </v-card-subtitle>
        </template>
        <v-row class="mb-3">
          <v-col cols="12" md="7">
            <v-card class="elevation-x d-flex align-center card-info">
              <v-list-item>
                <v-list-item-avatar size="60" class="mr-4">
                  <v-img src="@/assets/img/clinique.jpg" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="primary--text font-weight-medium white-space-inherit">
                    {{ stay.service.establishment.label }}
                  </v-list-item-title>
                  <v-list-item-subtitle v-if="establishmentAddress" class="font-weight-light text-caption font-alt white-space-inherit">
                    {{ establishmentAddress }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="font-weight-light text-caption font-alt white-space-inherit">
                    {{ stay.service.label }} ({{ labelStayType(stay.type) }})
                    {{ stay.doctor ? ' avec ' + stay.doctor.firstname + ' ' + stay.doctor.lastname : '' }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="12" md="5">
            <v-card class="elevation-x d-flex align-center card-info">
              <v-list-item>
                <v-list-item-avatar size="60" class="mr-2">
                  <font-awesome-icon :icon="['fad', 'alarm-clock']" size="3x" class="primary--text" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="primary--text font-weight-medium">
                    {{ $dayjs(stay.startDate).format('LL') }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="font-weight-light text-caption font-alt white-space-inherit">
                    Votre date
                    d'admission
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
        <v-card v-if="stay.status === 'in_progress' && !archiveStay" class="elevation-x">
          <v-stepper v-model="step" non-linear class="transparent" elevation="0">
            <v-stepper-header class="elevation-0">
              <v-stepper-step :complete="step > 1" step="1" editable edit-icon="$success">
                Patient
                <small>Informations générales</small>
              </v-stepper-step>

              <v-stepper-step v-if="surveyFieldSets.length" :complete="step > 2" step="2" editable edit-icon="$success">
                Questionnaire
                <small>Questionnaire d'entrée</small>
              </v-stepper-step>

              <v-stepper-step v-if="documentFieldSets.length" :complete="step > documentFieldSetsStepIndex"
                              :step="documentFieldSetsStepIndex" editable edit-icon="$success"
              >
                Documents
                <small>Documents nécessaires</small>
              </v-stepper-step>

              <v-stepper-step :complete="step > validationStepIndex" editable edit-icon="$success"
                              :step="validationStepIndex"
              >
                Validation
                <small>Résumé de la demande</small>
              </v-stepper-step>
            </v-stepper-header>

            <div class="splitter mt-1" />

            <v-stepper-content step="1">
              <patient-information ref="patientInformationForm" v-model="model.patient" />
              <v-card-actions class="d-flex justify-end mt-5">
                <v-btn small color="primary" class="elevation-0 font-alt" @click="forward">Continuer</v-btn>
              </v-card-actions>
            </v-stepper-content>

            <v-stepper-content v-if="surveyFieldSets.length" step="2">
              <survey v-model="model.form" :field-sets="surveyFieldSets" />
              <v-card-actions class="d-flex justify-end mt-5">
                <v-btn small class="elevation-0 font-alt" @click="backward">Retour</v-btn>
                <v-btn small color="primary" class="elevation-0 font-alt" @click="forward">Continuer</v-btn>
              </v-card-actions>
            </v-stepper-content>

            <v-stepper-content v-if="documentFieldSets.length" :step="documentFieldSetsStepIndex">
              <documents v-model="model.form" :field-sets="documentFieldSets" />
              <v-card-actions class="d-flex justify-end mt-5">
                <v-btn small class="elevation-0 font-alt" @click="backward">Retour</v-btn>
                <v-btn small color="primary" class="elevation-0 font-alt" @click="forward">Continuer</v-btn>
              </v-card-actions>
            </v-stepper-content>

            <v-stepper-content :step="validationStepIndex">
              <admission-form-summary :form="form" :model="model" @back="backward" />

              <v-card-actions class="d-flex justify-end mt-5">
                <v-btn small class="elevation-0 font-alt" @click="backward">Retour</v-btn>
                <v-btn small color="success" :disabled="submitting" :loading="submitting" class="elevation-0 font-alt"
                       @click="submit"
                >
                  Envoyer
                </v-btn>
              </v-card-actions>
            </v-stepper-content>
          </v-stepper>
        </v-card>
        <admission-summary v-else :form="form" :field-responses="formResponse ? formResponse.fieldResponses : []"
                           :patient="stay.patient" class="mb-5"
        />
      </v-col>
    </v-row>
    <modal v-model="welcomeDialog" title="Nouvelle admission" color="color-dialog informations"
           subtitle="Une demande d'admission a été initiée pour vous, veuillez remplir le formulaire d'admission.
            Vous pouvez modifier votre dossier jusqu'à la veille de votre date d'admission."
           :img="require('@/assets/img/illustrations/new-entry.svg')" :actions="true"
    >
      <template v-slot:actions>
        <v-btn color="primary" small text @click="welcomeDialog = !welcomeDialog">
          J'ai compris
        </v-btn>
      </template>
    </modal>
    <modal v-model="submitDialog" title="Dossier envoyé" color="color-dialog validate"
           :subtitle="successDialogSubtitle"
           :img="require('@/assets/img/illustrations/complete.svg')" :actions="false"
    />
  </v-container>
  <loader v-else />
</template>

<script>
  import Loader from '@/modules/core/components/Loader'
  import Modal from '@/modules/core/components/Modal'
  import AdmissionSummary from '@/modules/patients/components/AdmissionSummary'
  import api from '@/services/api'
  import PatientInformation from '@/modules/patients/components/form/PatientInformation'
  import Survey from '@/modules/patients/components/form/Survey'
  import Documents from '@/modules/patients/components/form/Documents'
  import AdmissionStatus from '@/modules/patients/components/AdmissionStatus'
  import * as forms from '@/modules/forms/api'
  import * as admissions from '@/modules/stays/api/admissions'
  import AdmissionFormSummary from '@/modules/patients/components/form/AdmissionFormSummary'
  import patientModel, {denormalize as denormalizePatient, normalize as normalizePatient} from '@/models/patient'
  import {column, concat} from '@/utils/array'
  import {empty} from '@/utils/variables'
  import {createModelForm} from '@/services/model'
  import dataDisplayers from '@/mixins/dataDisplayers'
  import logger from '@/services/logger'

  export default {
    name: 'AdmissionForm',
    components: {
      AdmissionFormSummary,
      PatientInformation,
      Survey,
      Documents,
      AdmissionStatus,
      AdmissionSummary,
      Modal,
      Loader
    },
    mixins: [dataDisplayers],
    data() {
      return {
        archiveStay: false,
        submitDialog: false,
        alreadySend: false,
        welcomeDialog: false,
        loading: true,
        submitting: false,
        step: 1,
        stay: null,
        form: null,
        formResponse: null,
        dialog: true,
        emailChanged: false,
        emailAlreadyUsed: false,
        model: {
          form: {},
          patient: createModelForm(patientModel)
        },
        comeFromList: false
      }
    },
    computed: {
      isCancelled() {
        return this.stay.status === 'cancelled'
      },
      surveyFieldSets() {
        return this.form ? this.form.fieldSets.filter(fieldSet => fieldSet.fields.find(field => 'document' !== field.type)) : []
      },
      documentFieldSets() {
        return this.form ? this.form.fieldSets.filter(fieldSet => fieldSet.fields.find(field => 'document' === field.type)) : []
      },
      fields() {
        return this.form ? concat(column(this.form.fieldSets, 'fields')) : []
      },
      establishmentAddress() {
        let value = []
        let address = this.stay.service.establishment.address
        if (address) {
          value.push(address.street)
          value.push([address.zipCode, address.city].filter(Boolean).join(' '))
          if (address.country && address.country !== 'FR') {
            value.push(address.country)
          }
        }
        return value.filter(Boolean).join(', ')
      },
      documentFieldSetsStepIndex() {
        return this.surveyFieldSets.length ? 3 : 2
      },
      validationStepIndex() {
        return this.surveyFieldSets.length ? (this.documentFieldSets.length ? 4 : 3) : this.documentFieldSets.length ? 3 : 2
      },
      successDialogSubtitle() {
        let subtitle = 'Votre dossier vient d\'être transmis avec succès.'
        if (this.emailChanged) {
          subtitle += '\nUn e-mail vous a été envoyé pour confirmer le changement d\'adresse e-mail'
        }
        return subtitle
      }
    },
    async created() {
      await this.loadStay()
      await this.assembleResponses()
      this.checkStayStatus()
      this.loading = false
    },
    methods: {
      checkStayStatus() {
        let today = this.$dayjs(new Date())
        let stayDate = this.$dayjs(this.stay.startDate)
        if (stayDate < today) {
          this.archiveStay = true
          this.welcomeDialog = false
        }
      },
      async loadStay() {
        try {
          this.stay = (await admissions.get(this.$route.params.stayId)).data
          if(this.stay.formId) {
            this.form = (await api.get(this.stay.formId)).data
          }
          this.comeFromList = this.$route.params.comeFromList
        } catch (error) {
          this.$root.$emit('snackbar:open', {
            snackbar: () => import('@/modules/core/components/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue'
          })
        }
      },
      async assembleResponses() {
        if (this.stay) {
          this.model.patient = denormalizePatient(this.stay.patient)

          if (this.stay.formResponseId) {
            this.formResponse = (await api.get(this.stay.formResponseId)).data
            this.model.form = Object.assign({}, this.model.form, Object.fromEntries(this.fields.map(field => {
              let response = this.formResponse.fieldResponses.find(response => response.field['@id'] === field['@id'])
              let responseValue = ''
              if (response) {
                responseValue = field.type === 'document' ? response.media : response.response
              }
              return [field['@id'], responseValue]
            })))
          }
          this.welcomeDialog = this.form && this.formResponse === null
        }
      },
      forward() {
        if (this.$refs.patientInformationForm.validate()) {
          this.scrollUp()
          this.step++
        }
      },
      backward() {
        this.scrollUp()
        this.step--
      },
      async submit() {
        if (this.$refs.patientInformationForm.validate()) {
          try {
            this.submitting = true

            this.emailChanged = this.model.patient.email !== this.stay.patient.email
            try {
              this.model.patient = (await admissions.update(this.stay.id, {
                patient: normalizePatient(this.model.patient)
              }, true)).data.patient
            } catch (error) {
              let violations = error.response && error.response.data ? error.response.data.violations : []
              let isEmailAlreadyUsed = violations.some(violation => violation.code === 'email_already_used')
              if (isEmailAlreadyUsed) {
                this.$root.$emit('snackbar:open', {
                  snackbar: () => import('@/modules/core/components/Snackbar'),
                  type: 'error',
                  title: 'L\'adresse e-mail est déjà utilisée'
                })
                this.step = 1
                return
              } else {
                throw error
              }
            }

            if (this.form) {
              let fieldResponses = []

              for (let field of this.fields) {
                let data = {
                  response: null,
                  media: null
                }

                if (this.formResponse) {
                  let fieldResponse = this.formResponse.fieldResponses.find(fieldResponse => fieldResponse.field['@id'] === field['@id'])
                  if (!fieldResponse) {
                    throw new Error(`Missing field response for field ${field['@id']} in form ${this.form['@id']}`)
                  }

                  data['@id'] = fieldResponse['@id']
                } else {
                  data.field = field['@id']
                }

                let response = this.model.form[field['@id']]

                if (!empty(response)) {
                  if (field.type === 'document') {
                    data.media = response['@id']
                  } else {
                    data.response = response
                  }
                }
                fieldResponses.push(data)
              }
              if (this.formResponse) {
                this.formResponse = (await forms.responses.update(this.formResponse.id, {fieldResponses})).data
              } else {
                this.formResponse = {
                  form: this.form['@id'],
                  stay: this.stay['@id'],
                  fieldResponses
                }

                this.formResponse = (await forms.responses.create(this.formResponse)).data
              }
            }

            this.submitDialog = true
            this.step = 1
          } catch (error) {
            logger.error(error)
            this.$root.$emit('snackbar:open', {
              snackbar: () => import('@/modules/core/components/Snackbar'),
              type: 'error',
              title: 'Une erreur est survenue'
            })
          } finally {
            this.submitting = false
          }
        } else {
          this.step = 1
        }
      },
      backToList() {
        this.$router.push({name: 'patient-homepage'})
      },
      scrollUp() {
        window.scrollTo(0, 0)
      }
    }
  }
</script>
