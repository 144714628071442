<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <div v-if="survey">
    <v-card class="elevation-x">
      <v-list-item>
        <v-list-item-icon class="mr-1">
          <v-img :src="require('@/assets/img/illustrations/survey_header.svg')" max-width="70px" width="70px"
                 max-height="70px" contain
          />
        </v-list-item-icon>
        <v-list-item-content>
          <v-card-title class="font-alt pb-2">{{ survey.name }}</v-card-title>
          <v-card-subtitle class="o-60">
            <span class="secondary--text font-weight-bold">{{ activeEstablishment.label }}</span>
          </v-card-subtitle>
        </v-list-item-content>
        <v-list-item-action class="d-flex flex-row align-center">
          <v-btn x-small rounded color="transparent grey--text text--darken-2"
                 class="font-alt btn-add mr-2"
                 elevation="0" dark
                 @click="showEditSurvey = !showEditSurvey"
          >
            <font-awesome-icon :icon="['fad','pencil']" size="sm" class="mr-2" />
            Éditer
          </v-btn>
        </v-list-item-action>

        <v-chip v-if="!valid" small>
          Le formulaire contient des erreurs
        </v-chip>
        <v-btn v-else x-small rounded color="success" class="font-alt btn-add mr-2"
               elevation="0" dark :loading="activating" :disabled="activating"
               @click.stop="showActivationDialog"
        >
          <font-awesome-icon :icon="['fad','check-double']" size="sm" class="mr-2" />
          {{
            survey.alreadyBeenActivated === true ? (surveyServices.length ? 'Questionnaire actif' : 'Aucun service rattachés à ce questionnaire') : 'Activer ce questionnaire'
          }}
        </v-btn>
      </v-list-item>
      <v-list v-if="!loading">
        <survey-dialog v-if="showEditSurvey" :survey="survey" :is-active="isActive" @close="close" @update-survey="updateSurvey" />
      </v-list>
      <modal v-model="activationDialog" title="Activer le questionnaire" color="color-dialog informations"
             subtitle="Une fois le questionnaire activé, aucune modification ne pourra être effectuée. Êtes vous certain de vouloir valider ce questionnaire ?"
             :img="require('@/assets/img/illustrations/new-entry.svg')" :actions="true"
      >
        <template v-slot:actions>
          <v-btn color="primary" small text @click="activateSurvey">
            Oui j'ai compris
          </v-btn>
        </template>
      </modal>
    </v-card>
    <survey-editor :survey="survey" :read-only="survey.alreadyBeenActivated === true" :valid.sync="valid" />
  </div>
</template>

<script>
  import Modal from '@/modules/core/components/Modal'
  import SurveyDialog from '@/modules/surveys/components/SurveyDialog'
  import { mapGetters, mapState } from 'vuex'
  import SurveyEditor from '@/modules/surveys/components/SurveyEditor'
  import { get, update } from '@/modules/surveys/api/surveys'
  import api from '@/services/api'

  export default {
    name: 'SurveyUpdate',
    components: {SurveyEditor, SurveyDialog, Modal},
    props: {
      id: String
    },
    data() {
      return {
        activationDialog: false,
        showEditSurvey: false,
        submitting: false,
        activating: false,
        survey: null,
        loading: true,
        valid: false
      }
    },
    computed: {
      ...mapState('establishments', ['activeEstablishmentServices']),
      ...mapGetters('establishments', ['activeEstablishment']),
      activeServices() {
        return this.activeEstablishmentServices.filter(service => service.activeSurvey === '/api/surveys/' + this.survey.id)
      },
      surveyServices() {
        if (!this.survey) {
          return []
        }
        let services
        if (this.survey.alreadyBeenActivated === true) {
          services = this.activeServices
        } else {
          services = this.survey.services
        }
        return services
      },
      isActive() {
        return Boolean(this.survey.alreadyBeenActivated === true)
      }
    },
    watch: {
      '$store.state.establishments.activeEstablishmentId'() {
        this.$router.push({name: 'surveys-list'})
      }
    },
    async created() {
      await this.loadSurvey()

      this.loading = false
    },
    methods: {
      showActivationDialog() {
        if (!this.survey.alreadyBeenActivated) {
          this.activationDialog = true
        }
      },
      async submit() {
        try {
          this.submitting = true
          await update(this.survey.id, this.model)
        } catch (error) {
          this.$root.$emit('snackbar:open', {
            snackbar: () => import('@/modules/core/components/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue'
          })
        } finally {
          this.submitting = false
          this.activationDialog = false
        }
      },
      async loadSurvey() {
        try {
          this.survey = (await get(this.id, {cache: {ignoreCache: true}})).data
        } catch (error) {
          this.$root.$emit('snackbar:open', {
            snackbar: () => import('@/modules/core/components/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue'
          })
        }
      },
      async activateSurvey() {
        try {
          this.activating = true
          await api.put(`/surveys/${this.id}/activate`)
          this.$store.commit('establishments/setServicesActiveSurvey', this.survey)
          await this.$router.push({name: 'surveys-list'})
        } catch (error) {
          this.$root.$emit('snackbar:open', {
            snackbar: () => import('@/modules/core/components/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue'
          })
        } finally {
          this.activating = false
        }
      },
      close() {
        this.showEditSurvey = false
      },
      async updateSurvey(data) {
        if (data) {
          Object.assign(this.survey, data)
        }
      }
    }
  }
</script>
