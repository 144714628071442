<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <div v-if="!initLoading">
    <v-data-table
      :headers="headers"
      :items="surveys"
      hide-default-footer
      :page.sync="page"
      :options.sync="options"
      :items-per-page="itemsPerPage"
      class="c-pointer pt-5"
      :server-items-length="totalItems"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      multi-sort
      :loading="isLoading"
      loading-text="Chargement des données en cours.. Veuillez patienter"
      :item-class="itemRowBackground"
      @click:row="selectItem"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-spacer />
          <v-btn x-small tile elevation="0" class="ml-2" @click="resetSorting">
            Réinitialiser les tris
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.stay.patientIdentity.birthDate="{ item }">
        <span class="text-caption o-80">{{ $dayjs(item.stay.patientIdentity.birthDate).format('DD/MM/YYYY') }}</span>
      </template>
      <template v-slot:item.stay.startDate="{ item }">
        <span class="text-caption">{{ $dayjs(item.stay.startDate).format('DD/MM/YYYY') }}</span>
      </template>
      <template v-slot:item.phone="{ item }">
        <div v-if="item.stay.patientIdentity.mobilePhone || item.stay.patientIdentity.phone">
          <v-btn rounded x-small :color="$vuetify.theme.dark ? 'grey darken-2' : 'grey lighten-4'">
            <font-awesome-icon :icon="['fad', 'phone']" small size="1x" class="mr-1" />
            {{ item.stay.patientIdentity.mobilePhone || item.stay.patientIdentity.phone }}
          </v-btn>
        </div>
      </template>

      <template v-slot:item.stay.service.label="{ item }">
        <span class="text-caption o-80">{{ item.stay.service.label }}</span>
      </template>
      <template v-slot:item.alertLevel="{ item }">
        <v-avatar size="30" class="font-weight-bold text-h6">
          <font-awesome-icon
            v-if="item.surveyResponseHighAlertsCount || item.surveyResponseMediumAlertsCount || item.surveyResponseLowAlertsCount"
            :icon="['fad', 'exclamation-triangle']" size="1x" :class="alertLevelToIconColor(item)"
          />
        </v-avatar>
      </template>
      <template v-slot:item.surveyResponseStatus="{ item }">
        <v-chip v-if="item.surveyResponseStatus === 'acknowledged'" color="success" small>Dossier acquitté</v-chip>
        <v-chip v-else small> Dossier clôturé</v-chip>
      </template>
    </v-data-table>
    <div v-if="totalItems > itemsPerPage" class="py-3 d-flex justify-center">
      <v-pagination
        v-model="page"
        :length="pageCount"
        total-visible="5"
      />
    </div>
  </div>
</template>

<script>
  import * as surveys from '@/modules/surveys/api'
  import dataDisplayers from '@/mixins/dataDisplayers'
  import {removeItem} from '@/utils/array'
  import LocalStorage from '@/utils/LocalStorage'
  import {clean, deepCopy} from '@/utils/object'

  const localDB = new LocalStorage()
  const initalItemsPerPage = 10

  export default {
    name: 'SurveysList',
    mixins: [dataDisplayers],
    props: {
      unanswered: Boolean,
      answered: Boolean,
      acquit: Boolean,
      unansweredCounter: Number,
      alertCounterLow: Number,
      alertCounterMedium: Number,
      alertCounterHigh: Number
    },
    data() {
      let headers = [
        {
          text: 'Date du séjour',
          value: 'stay.startDate',
          align: 'left'
        },
        {
          text: 'Nom usuel',
          value: 'stay.patientIdentity.lastName'
        },
        {
          text: 'Prénom',
          value: 'stay.patientIdentity.firstName'
        },
        {
          text: 'Date de naissance',
          value: 'stay.patientIdentity.birthDate',
          align: 'center'
        },
        {
          text: 'Numéro de séjour',
          value: 'stay.number',
          align: 'center'
        },
        {
          text: 'Service',
          value: 'stay.service.label'
        },
        {
          text: 'Téléphone',
          value: 'phone'
        }
      ]

      if (this.answered) {
        headers.push({
          text: 'Niveau d\'alerte',
          value: 'alertLevel',
          align: 'center'
        })
      }
      if (this.acquit) {
        headers.push({
          text: 'Status',
          value: 'surveyResponseStatus'
        })
      }

      return {
        initLoading: true,
        page: 1,
        surveys: [],
        pageCount: 0,
        options: {},
        totalItems: 0,
        itemsPerPage: null,
        isLoading: false,
        sortBy: null,
        sortDesc: null,
        filters: null,
        headers
      }
    },
    computed: {
      initalSorting() {
        return {
          sortBy: ['stay.startDate'],
          sortDesc: [true]
        }
      },
      sortByStoreKey() {
        if (this.acquit) {
          return 'acquit_survey_sortby'
        } else if (this.answered) {
          return 'answered_survey_sortby'
        } else {
          return 'unanswered_survey_sortby'
        }
      },
      sortDescStoreKey() {
        if (this.acquit) {
          return 'acquit_survey_sortdesc'
        } else if (this.answered) {
          return 'answered_survey_sortdesc'
        } else {
          return 'unanswered_survey_sortdesc'
        }
      },
      itemsPerPageStoreKey() {
        if (this.acquit) {
          return 'acquit_survey_items'
        } else if (this.answered) {
          return 'answered_survey_items'
        } else {
          return 'unanswered_survey_items'
        }
      },
      params() {
        let params = {
          page: this.page,
          itemsPerPage: this.itemsPerPage
        }

        if (this.sortBy.length > 0) {
          params.order = {}
          this.sortBy.forEach((value, index) => {
            params.order[value] = (this.sortDesc[index]) ? 'desc' : 'asc'
          })
        }
        return params
      }
    },
    watch: {
      options: {
        handler() {
          this.$nextTick(this.loadSurveys)
        },
        deep: true
      },
      sortBy: function (value) {
        localDB.set(this.sortByStoreKey, value)
      },
      sortDesc: function (value) {
        localDB.set(this.sortDescStoreKey, value)
      }
    },
    async created() {
      if (!localDB.get(this.itemsPerPageStoreKey)) {
        localDB.set(this.itemsPerPageStoreKey, initalItemsPerPage)
      }

      if (!localDB.get(this.sortByStoreKey)) {
        localDB.set(this.sortByStoreKey, this.initalSorting.sortBy)
      }

      if (!localDB.get(this.sortDescStoreKey)) {
        localDB.set(this.sortDescStoreKey, this.initalSorting.sortDesc)
      }

      this.options.itemsPerPage = this.itemsPerPage = localDB.get(this.itemsPerPageStoreKey) ?? initalItemsPerPage
      this.sortBy = localDB.get(this.sortByStoreKey) ?? this.initalSorting.sortBy
      this.sortDesc = localDB.get(this.sortDescStoreKey) ?? this.initalSorting.sortDesc

      this.initLoading = false
    },
    methods: {
      resetSorting() {
        this.sortBy = this.initalSorting.sortBy
        this.sortDesc = this.initalSorting.sortDesc

        localDB.set(this.sortByStoreKey, this.initalSorting.sortBy)
        localDB.set(this.sortDescStoreKey, this.initalSorting.sortDesc)
      },
      async loadSurveys() {
        this.isLoading = true
        let params = clean(deepCopy(this.params))

        for (let i in this.sortBy) {
          if (!Object.prototype.hasOwnProperty.call(this.sortBy, i)) {
            continue
          }
          params.order[this.sortBy[i]] = this.sortDesc[i] ? 'desc' : 'asc'
        }

        let response
        if (this.unanswered) {
          response = await surveys.staySurveys.list({
            params: {
              ...params,
              surveyResponseStatus: ['in_progress', 'init'],
              archived: false
            }
          })
        } else if (this.answered) {
          response = await surveys.staySurveys.list({
            params: {
              ...params,
              surveyResponseStatus: 'complete',
              archived: false
            }
          })
        } else if (this.acquit) {
          response = await surveys.staySurveys.list({
            params: {
              ...params,
              archived: true
            }
          })
        } else {
          throw new Error('Unexpected SurveysList props')
        }

        this.surveys = response.data['hydra:member']
        this.totalItems = response.data['hydra:totalItems']
        this.$emit('update:total', this.totalItems)
        this.pageCount = Math.ceil(this.totalItems / this.itemsPerPage)
        this.isLoading = false
      },
      removeItem(id) {
        let survey = this.surveys.find(survey => survey.id === id)
        removeItem(this.surveys, survey)
      },
      selectItem(value) {
        this.$emit('select-item', value)
      },
      alertLevelToIconColor(item) {
        let color = 'grey--text'
        if (item.surveyResponseHighAlertsCount) {
          color = 'red--text darken-2'
        } else if (item.surveyResponseMediumAlertsCount) {
          color = 'orange--text darken-3'
        } else if (item.surveyResponseLowAlertsCount) {
          color = 'yellow--text darken-2'
        }
        return color
      },
      itemRowBackground: function (item) {
        if(!item.surveyResponseId && this.acquit) {
          return 'grey--text text--lighten'
        }
      }
    }
  }
</script>
