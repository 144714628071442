<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-card class="elevation-x">
    <v-card-title class=" font-alt pb-3">Questionnaires</v-card-title>
    <v-card-subtitle class="o-60">Liste des questionnaires activés et désactivés</v-card-subtitle>
    <skeleton-list v-if="loading" />
    <v-list v-else-if="!activeEstablishment.surveyFeatureActivated" class="pa-0">
      <v-list-item two-line :class="$vuetify.theme.dark ? '' : 'grey lighten-4'"
                   @click.stop="activate"
      >
        <v-list-item-avatar>
          <v-avatar size="40" color="success lighten-1">
            <font-awesome-icon :icon="['fas', 'check']" size="1x" class="white--text" />
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="font-alt font-weight-medium">
            Activer les questionnaires
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list v-else class="pa-0">
      <v-list-item two-line class="click-to-create" :class="$vuetify.theme.dark ? '' : 'grey lighten-4'"
                   @click.stop="surveyCreate = true"
      >
        <v-list-item-avatar>
          <v-avatar size="40" color="success lighten-1">
            <font-awesome-icon :icon="['fas', 'plus']" size="1x" class="white--text" />
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="font-alt font-weight-medium">
            Ajouter un questionnaire
          </v-list-item-title>
          <v-list-item-subtitle class="op-60 font-weight-light">Créer un nouveau questionnaire</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <empty-list v-if="surveys.length === 0" title="Aucun questionnaire"
                  subtitle="L'établissement ne possède aucun questionnaire de suivi post-opératoire."
      />
      <template v-for="(survey, index) in surveys">
        <v-list-item :key="survey.id" two-line :to="{ name: 'survey-write', params:{id: survey.id}}">
          <v-list-item-avatar>
            <v-avatar :color="activeSurvey(survey) && showServices(survey) ? 'success' : 'grey lighten-2'" size="40" class="white--text">
              <font-awesome-icon v-if="activeSurvey(survey)" :icon="['fad', 'check-double']" size="1x"
                                 class="white--text"
              />
              <font-awesome-icon v-else :icon="['fas', 'times']" size="1x" class="white--text" />
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="font-alt font-weight-normal">{{ survey.name }}</v-list-item-title>
            <v-list-item-subtitle class="op-60 font-weight-light">
              {{ showServices(survey) || 'Aucun service rattaché à ce questionnaire' }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-if="index < surveys.length -1" :key="index" />
      </template>
    </v-list>
    <survey-dialog v-if="surveyCreate" @close="closeDialog" @add-survey="addListItem" />
  </v-card>
</template>

<script>
  import SkeletonList from '@/modules/core/components/SkeletonList'
  import EmptyList from '@/modules/core/components/EmptyList'
  import SurveyDialog from '@/modules/surveys/components/SurveyDialog'
  import * as services from '@/modules/services/api/services'
  import * as surveys from '@/modules/surveys/api/surveys'
  import { mapGetters } from 'vuex'
  import { column } from '@/utils/array'

  export default {
    name: 'SurveysList',
    components: {SurveyDialog, EmptyList, SkeletonList},
    data() {
      return {
        loading: true,
        activating: true,
        surveys: [],
        services: [],
        surveyCreate: false
      }
    },
    computed: {
      ...mapGetters('establishments', ['activeEstablishment']),
      activeSurvey() {
        return survey => survey['alreadyBeenActivated'] === true
      }
    },
    watch: {
      activeEstablishment: {
        handler() {
          this.load()
        },
        immediate: true
      }
    },
    methods: {
      async load() {
        let establishmentId = this.$store.state.establishments.activeEstablishmentId
        if (!establishmentId) {
          return
        }
        this.loading = true
        try {
          this.services = (await services.list({params: {establishment: establishmentId}})).data['hydra:member']
          this.surveys = (await surveys.list({params: {establishment: establishmentId}})).data
        } catch (error) {
          this.$root.$emit('error', error)
        } finally {
          this.loading = false
        }
      },
      async activate() {
        this.loading = true
        await this.$store.dispatch('establishments/activateSurveyFeature')
        await this.load()
      },
      addListItem(data) {
        if (data) {
          this.surveys.push(data)
        }
      },
      closeDialog() {
        this.surveyCreate = false
      },
      showServices(survey) {
        let services
        if (survey.alreadyBeenActivated === true) {
          services = this.services.filter(service => service.activeSurvey === '/api/surveys/' + survey.id)
        } else {
          services = survey.services
        }
        return column(services, 'label').join(', ')
      }
    }
  }
</script>
